import Css from "./style.module.scss";

import React from "react";

const Spinner = () => {
  return (
    <div className={Css.spinner} />
  );
};

export default Spinner;

import React from "react";

const Icon = (props) => {
  const {
    d,
    paths = [d],
    viewBox = "0 0 18 18",
    stroke = "currentColor",
    fill,
    children,
    strokeWidth = "1.5",
    ...restProps
  } = props;

  return (
    <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g stroke={stroke} fill={fill}>
        {paths && (
          <g strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round">
            {paths.map((path, index) => (<path key={String(index)} d={path} />))}
          </g>
        )}
        {children}
      </g>
    </svg>
  );
};

export default Icon;

import { NODE_ENV_TYPES } from "const";

const GLOBALS = {};

export const scriptExists = (src) => {
  try {
    return !!document.head.querySelector(`[src="${src}"]`);
  } catch (exception) {
    return false;
  }
};

export const addScript = (src, { async } = {}, onload) => {
  const script = document.createElement("script");

  script.addEventListener("load", onload);
  script.async = async;
  script.src = src;
  document.head.appendChild(script);
};

export const emptyObject = (object) => !object || !Object.values(object).filter(Boolean).length;

export const preloadImages = (imagesUrls) => {
  return Promise.all(imagesUrls.map((imageUrl) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      const handleLoadComplete = () => resolve(image);

      image.src = imageUrl;
      if (image.complete || image.readyState === XMLHttpRequest.DONE) handleLoadComplete();
      else {
        image.addEventListener("load", handleLoadComplete);
        image.addEventListener("error", () => reject());
      }
    });
  }));
};

/* eslint-disable */

export const initGa = () => {
  (function(src, gaId, head, script) {
    head = document.getElementsByTagName("head")[0];
    script = document.createElement("script");
    script.async = 1;
    script.src = src + gaId;
    head.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || function() { window.dataLayer.push(arguments); };
    window.gtag("js", new Date());
    window.gtag("config", gaId);
  })("https://www.googletagmanager.com/gtag/js?id=", "G-22H805Y5Y2");
};

export const initHotjar = () => {
  (function(h, o, t, j, a, r) {
    h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments); };
    h._hjSettings = { hjid: 1881377, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script"); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
};

export const initFirstPromoter = () => {
  (function(w, d, u, h, s) {
    h = d.getElementsByTagName("head")[0];
    s = d.createElement("script"); s.async = 1; s.src = u;
    h.appendChild(s);
    w.fpr = w.fpr || function() {
      w.fpr.q = w.fpr.q || [];
      w.fpr.q[arguments[0] == "set" ? "unshift" : "push"](arguments);
    };
  })(window, document, "https://cdn.firstpromoter.com/fpr.js");
  window.fpr("init", { cid: "brk342fb" });
  window.fpr("click");
};

export const initIntercom = () => {
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "cjnkgoqp",
  };
  (function() {
    const w = window;

    const ic = w.Intercom;

    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;

      var i = function() { i.c(arguments); };

      i.q = [];
      i.c = function(args) { i.q.push(args); }; w.Intercom = i;

      const l = function() {
        const s = d.createElement("script");

        s.type = "text/javascript";
        s.async = 1;
        s.src = "https://widget.intercom.io/widget/cjnkgoqp";

        const x = d.getElementsByTagName("script")[0];

        x.parentNode.insertBefore(s, x);
      };

      if (document.readyState === "complete") { l(); }
      else if (w.attachEvent) { w.attachEvent("onload", l); }
      else { w.addEventListener("load", l, false); }
    }
  })();
};

/* eslint-enable */

export const checkIsMobileLayout = () => {
  return "ontouchstart" in window;
};

export const setTimeout = (callback, interval, ...restArgs) => {
  const timeoutId = window.setTimeout(callback, interval, ...restArgs);

  return () => clearTimeout(timeoutId);
};

export const checkIsProductionEnv = () => {
  return process.env.NEXT_PUBLIC_ENV_TYPE === NODE_ENV_TYPES.PRODUCTION;
};

export const storageValue = (key, value, useSessionStorage = false) => {
  const storage = useSessionStorage ? window.sessionStorage : window.localStorage;

  try {
    if (value === null) {
      storage.removeItem(key);

      return null;
    }
    if (value !== undefined) {
      storage.setItem(key, value);

      return value;
    }

    return storage.getItem(key);
  } catch (error) {
    return null;
  }
};

export const getGlobal = (key) => GLOBALS[key];

export const setGlobal = (key, value) => { GLOBALS[key] = value; };

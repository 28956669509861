import Css from "./style.module.scss";

import React, { useCallback, useEffect, useState } from "react";

import IconClose from "lib/icons/IconClose";
import classNames from "classnames";

const REMOVE = "REMOVE";

class Store {
  constructor(initialState) {
    this.state = initialState;
  }

  setState(state) {
    this.state = state;
    this.listener(state);
  }

  onChange(listener) {
    this.listener = listener;
  }

  dispatch({ type, payload }) {
    if (type === REMOVE) {
      this.setState(
        payload
          ? this.state.filter((item) => item.uid !== payload)
          : [...this.state.slice(0, -1)],
      );
    } else {
      this.setState([...this.state, payload]);
    }
  }
}

const store = new Store([]);

let lastUid = 0;

const Dialog = ({ message, render, onClose }) => {
  const handleCloseClick = useCallback(() => {
    onClose(null);
  }, [onClose]);

  return (
    <div className={classNames(Css.window, !render && Css.simpleText)}>
      <div className={Css.inner}>
        <div className={Css.close} onClick={handleCloseClick}><IconClose /></div>
        <div className={Css.message}>
          {render ? render({ onClose }) : message}
        </div>
      </div>
    </div>
  );
};

const Dialogs = () => {
  const [dialogs, setDialogs] = useState([]);

  const handleOverlayClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      store.dispatch({ type: REMOVE });
    }
  }, []);

  useEffect(() => {
    store.onChange((state) => setDialogs(state));
  }, []);

  if (!dialogs?.length) {
    return null;
  }

  return (
    <div className={Css.dialog} onClick={handleOverlayClick}>
      {dialogs.map(({ uid, ...rest }) => (
        <Dialog key={uid} {...rest} />
      ))}
    </div>
  );
};

export default Dialogs;

export const showDialog = (data) => {
  const uid = ++lastUid;

  return new Promise((resolve) => {
    store.dispatch({
      payload: {
        uid,
        onClose(result) {
          store.dispatch({ type: REMOVE, payload: uid });
          resolve(result);
        },
        ...data,
      },
    });
  });
};

// eslint-disable-next-line no-console
export const API_URL = process.env.NEXT_PUBLIC_API_URL;

export const EMAIL = process.env.NEXT_PUBLIC_EMAIL;

export const CALENDLY_WIDGET_URL = "https://calendly.com/vadim-booke-ai/30min";

export const AUTH_URL = "https://app.booke.ai/login";

export const SIGNUP_URL = "https://auth.booke.ai/en/?view=signup";

export const PAGE_DEMO_PATH = "/accounting-automation-demo";

export const PAGE_REQUEST_ACCESS_PATH = "/request-access";

// Dynamic pages revalidate interval in seconds
export const REVALIDATE_INTERVAL = 3600;

export const POSTS_PER_PAGE = 12;

export const PRICING_PLANS = {
  PREMIUM: "premium",
  SMART: "smart",
  ROBOTIC: "robotic",
  ENTERPRISE: "enterprise",
};

export const NODE_ENV_TYPES = {
  PRODUCTION: "production",
  STAGING: "staging",
};

export const COUNTRY_TO_LOCALE = {
  US: "us",
  CA: "ca",
  AU: "au",
  NZ: "nz",
  GB: "uk",
  EN: "en",
  CZ: "cs",
};

export const LOCALE_TO_LANG = {
  us: "en-US",
  ca: "en-CA",
  au: "en-AU",
  nz: "en-NZ",
  uk: "en-GB",
  cs: "cs-CZ",
};

export const COUNTRY_SPECIFIC_PAGES = [
  "",
  "pricing",
];

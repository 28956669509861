import Head from "next/head";
import React, { useEffect, useState } from "react";

import { COUNTRY_SPECIFIC_PAGES, COUNTRY_TO_LOCALE, LOCALE_TO_LANG } from "const";
import { checkIsProductionEnv, getGlobal, initFirstPromoter, initGa, initHotjar, initIntercom, setGlobal, storageValue } from "utils";
import { useRouter } from "next/router";
import Dialogs from "lib/common/Dialogs";
import Spinner from "lib/common/Spinner";
import useTexts from "hooks/useTexts";

const COMPANY_NAME = "Booke AI";

const DEFAULT_TITLE = "Automate your bookkeeping with an AI-driven single app";

const getIpInfo = async({ locale, pathname }) => {
  if (location.hostname === "localhost") return;

  try {
    const response = await fetch("https://ipinfo.io/json?token=72a1e19ae624a5");

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const json = await response.json();

    const { country } = json;

    setGlobal("country", country);

    const [, pageName] = pathname.split("/");

    if (
      COUNTRY_SPECIFIC_PAGES.includes(pageName)
      && COUNTRY_TO_LOCALE[country]
      && COUNTRY_TO_LOCALE[country] !== locale
    ) {
      window.location.replace(`/${COUNTRY_TO_LOCALE[country]}${pathname}`);
    }
  } catch (exception) {}
};

const scrollToTarget = () => {
  if (!location.hash) return;

  const targetElement = document.getElementById(location.hash.slice(1));

  if (!targetElement || !targetElement.scrollIntoView) return;

  targetElement.scrollIntoView();
};

const handleDocumentLoad = () => {
  if (checkIsProductionEnv()) {
    initGa();
    initHotjar();
    initFirstPromoter();
    initIntercom();
  } else {
    window.gtag = () => {};
  }
};

const handleLinkClick = (event, target) => {
  if (target.origin === location.origin) {
    const [, pageName] = target.pathname.split("/");

    const country = getGlobal("country");

    if (COUNTRY_SPECIFIC_PAGES.includes(pageName) && COUNTRY_TO_LOCALE[country]) {
      event.preventDefault(event);
      window.location.href = `/${COUNTRY_TO_LOCALE[country]}${target.pathname}`;
    }
  }
};

const handleDocumentClick = (event) => {
  if (event.target.closest("a")) {
    handleLinkClick(event, event.target.closest("a"));
  }
};

const Document = (props) => {
  const texts = useTexts();

  const { locale, pathname, locales } = useRouter();

  const [preloaderShown, setPreloaderShown] = useState(true);

  const {
    seo = {},
    showPreloader,
    title = DEFAULT_TITLE,
    isseoContent = false,
    description = texts.homePageDescription,
    children,
  } = props;

  const seoTitle = `${isseoContent ? title : `${title} | ${COMPANY_NAME}`}`;

  const seoDescription = description;

  useEffect(() => {
    scrollToTarget();

    if (showPreloader) return;

    const preloader = document.getElementById("preloader");

    if (!preloader) return;

    setTimeout(() => {
      setPreloaderShown(false);
    // eslint-disable-next-line no-magic-numbers
    }, 600);
  }, [showPreloader]);

  useEffect(() => {
    const initPage = async() => {
      setGlobal("country", localStorage.country);
      try {
        await getIpInfo({ locale, pathname });
      } finally {
        setPreloaderShown(false);
      }
      if (!storageValue("referrer")) storageValue("referrer", document.referrer);
      if (document.readyState === "complete") handleDocumentLoad();
      else window.addEventListener("load", handleDocumentLoad);
      document.addEventListener("click", handleDocumentClick);
    };

    initPage();
  }, [locale, pathname]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#003166" />

        <title>{seoTitle}</title>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content="accounting, reconciliation, ocr, automation" />

        <meta httpEquiv="content-language" content="en-US" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://booke.ai" />
        <meta property="og:title" content={seo.og_title || seoTitle} />
        <meta property="og:description" content={seo.og_description || seoDescription} />
        <meta property="og:site_name" content="Booke AI" />
        <meta property="og:image:alt" content="Booke AI" />
        <meta property="og:image" content={seo.og_image || "https://booke.ai/images/preview.png"} />

        <meta property="twitter:title" content={seo.twitter_title || seoTitle} />
        <meta property="twitter:description" content={seo.twitter_description || seoDescription} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://booke.ai" />
        <meta property="twitter:image" content={seo.twitter_image || "https://booke.ai/images/preview.png"} />

        {COUNTRY_SPECIFIC_PAGES.includes(pathname.slice(1)) && locales.map((item) => {
          return LOCALE_TO_LANG[item] && (
            <link key={item} rel="alternate" hrefLang={LOCALE_TO_LANG[item]} href={`/${item}${pathname}`} />
          );
        })}
      </Head>
      {children}
      {preloaderShown && <div id="preloader"><Spinner /></div>}
      <Dialogs />
      <script
        src="https://app.termshub.io/68ce7580-4b34-4bf0-9c17-e08fef01bb97/termshub-cookie-consent.js"
        // eslint-disable-next-line react/no-unknown-property
        site="Booke_AI" />
    </>
  );
};

export default Document;
